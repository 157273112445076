import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { useStore } from './redux/store'

/* Theme variables */
import './theme/variables.css'
import './pages/global.css'

import './lang/i18n'
import React, { Suspense } from 'react'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'
import { CircularProgress, createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { CacheProvider } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useThemeDetector } from './hooks/useThemeDetector'
import Intro from './pages/Intro'

const Home = React.lazy(async () => await import('./pages/Home'))
const Phone = React.lazy(async () => await import('./pages/Phone'))
const Final = React.lazy(async () => await import('./pages/Final'))
const WebAuthRegister = React.lazy(async () => await import('./pages/WebAuthRegister'))
const ApplePay = React.lazy(async () => await import('./pages/ApplePay'))
const Checkout = React.lazy(async () => await import('./pages/Checkout'))
const SinglePageCheckout = React.lazy(async () => await import('./pages/SinglePageCheckout'))
const SinglePageAddress = React.lazy(async () => await import('./pages/SinglePageAddress'))
const SinglePageCard = React.lazy(async () => await import('./pages/SinglePageCard'))
const InstallmentSetup = React.lazy(async () => await import('./pages/InstallmentSetup'))
const Pay = React.lazy(async () => await import('./pages/Pay'))
const PaymentLink = React.lazy(async () => await import('./pages/PaymentLink'))
const ApplePayCheckout = React.lazy(async () => await import('./pages/ApplePayCheckout'))

const App: React.FC = () => {
  const { i18n } = useTranslation()
  const isDarkTheme = useThemeDetector()

  const store = useStore()
  const darkTheme = createTheme({
    direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Both here and <body dir="rtl">
    palette: {
      mode: 'dark',
      primary: {
        light: '#fff',
        main: '#fff',
        dark: '#fff',
        contrastText: '#000'
      },
      secondary: {
        light: '#5dec49',
        main: '#03b807',
        dark: '#5dec49',
        contrastText: '#fff'
      },
      background: {
        paper: '#02060a',
        default: '#151f29'
      }
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    },
    typography: {
      fontFamily: 'Cairo Regular, Cairo Medium, Cairo SemiBold, Cairo Bold, Cairo ExtraBold, Cairo ExtraLight, Cairo Black'
    }
  })
  const lightTheme = createTheme({
    direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Both here and <body dir="rtl">

    palette: {

      mode: 'light',
      primary: {
        light: '#1e212a',
        main: '#1e212a',
        dark: '#1e212a',
        contrastText: '#fff'
      },
      secondary: {
        light: '#5dec49',
        main: '#03b807',
        dark: '#008600',
        contrastText: '#fff'
      },
      background: {
        paper: '#f4f8fa',
        default: '#FFF'
      }
    },
    typography: {
      fontFamily: 'Cairo Regular, Cairo Medium, Cairo SemiBold, Cairo Bold, Cairo ExtraBold, Cairo ExtraLight, Cairo Black'
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }

  })
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: i18n.language === 'ar' ? [prefixer, rtlPlugin] : [prefixer]
  })

  return (

    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <CssBaseline />
      <CacheProvider value={cacheRtl}>
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={<div className="center"><CircularProgress /></div>}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path="/phone" element={<Phone />} />
                <Route path="/intro" element={<Intro />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/single-checkout" element={<SinglePageCheckout />} />
                <Route path="/single-address" element={<SinglePageAddress />} />
                <Route path="/single-card" element={<SinglePageCard />} />
                <Route path="/web-auth-register" element={<WebAuthRegister />} />
                <Route path="/pay/:id" element={<Pay />} />
                <Route path="/payment-link/:id" element={<PaymentLink />} />
                <Route path="/apple-pay" element={<ApplePay />} />
                <Route path="/apple-pay/:id" element={<ApplePay />} />
                <Route path="/apple-checkout" element={<ApplePayCheckout />} />
                <Route path="/installment-setup" element={<InstallmentSetup setCheckEligibility={undefined}/>} />
                <Route path="/installment-setup/ar" element={<InstallmentSetup useArabic={true} setCheckEligibility={undefined}/>} />
                <Route path="/final/:id" element={<Final />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </Provider>
      </CacheProvider>

    </ThemeProvider>

  )
}

export default App
